<template>
  <button
    :type="type"
    :form="form"
    :name="name"
    :disabled="isDisabled"
    :value="value"
    :class="[buttonColors, buttonDisabledClasses]"
    class="h-12 flex justify-center items-center font-roc-grotesk text-sm appearance-none rounded-lg focus:outline-none"
    @click="$emit('click', ($event.target as HTMLButtonElement).value)"
  >
    <span
      class="flex items-center"
    >
      <span
        v-if="icon && !loading && !finished"
      >
        <IconLoader
          class="w-6"
          :name="icon"
        />
      </span>
      <span
        v-if="loading || finished"
      >
        <IconLoader
          :key="iconName"
          class="w-6"
          :class="[{ 'animate-spin': loading }, loaderColor]"
          :name="iconName"
        />
      </span>
      <span
        v-else
      >
        {{ label }}
      </span>
    </span>
  </button>
</template>

<script setup lang="ts">
interface Props {
  type: 'button' | 'submit' | 'reset'
  form?: string
  name?: string
  disabled?: boolean
  label: string
  color?: 'agency' | 'error' | 'transparent' | 'transparentPurple' | 'transparentWithoutBorder'
  loaderColor?: 'agency' | 'error' | 'transparent' | 'transparentPurple' | 'transparentWithoutBorder'
  value?: string
  loading?: boolean
  finished?: boolean
  icon?: string
}
const props = withDefaults(defineProps<Props>(), {
  form: 'form',
  name: 'button',
  disabled: false,
  value: '',
  color: 'agency',
  loading: false,
  finished: false,
  loaderColor: 'agency'
})

interface Emits {
  (e: 'click', value: string): void
}
const emit = defineEmits<Emits>()

const buttonColorConfigurations: Record<string, string> = {
  agency: 'px-6 bg-16-Agency-500 border border-16-Agency-500 text-white hover:bg-16-Agency-600 hover:border-16-Agency-600 focus:shadow-button-focus-agency',
  error: 'px-6 bg-06-Error-400 border border-06-Error-400 text-white hover:border-06-Error-600 hover:bg-06-Error-600 focus:shadow-button-focus-error',
  transparent: 'px-6 bg-transparent border border-17-Grey-32% text-Light-01-Text-Primary hover:bg-Light-02-Hover focus:shadow-button-focus-transparent',
  transparentWithoutBorder: 'px-2.5 bg-transparent text-Light-01-Text-Primary hover:bg-Light-02-Hover focus:shadow-button-focus-transparent',
  transparentPurple: 'px-6 bg-transparent border border-16-Agency-48% text-16-Agency-500 hover:bg-16-Agency-8% focus:shadow-button-focus-agency'
}

const buttonDisabledConfiguration: Record<string, string> = {
  agency: 'disabled:border-Light-05-Disabled-Background disabled:bg-Light-05-Disabled-Background disabled:hover:border-Light-05-Disabled-Background disabled:hover:bg-Light-05-Disabled-Background disabled:text-Light-03-Text-Disabled',
  error: 'disabled:border-Light-05-Disabled-Background disabled:bg-Light-05-Disabled-Background disabled:hover:border-Light-05-Disabled-Background disabled:hover:bg-Light-05-Disabled-Background disabled:text-Light-03-Text-Disabled',
  transparent: 'disabled:border-07-Grey-200 disabled:text-07-Grey-400 disabled:hover:bg-transparent',
  transparentWithoutBorder: 'disabled:border-07-Grey-200 disabled:text-07-Grey-400 disabled:hover:bg-transparent',
  transparentPurple: ''
}

const loaderColorConfiguration: Record<string, string> = {
  agency: '',
  error: '',
  transparent: 'stroke-Light-01-Text-Primary',
  transparentWithoutBorder: '',
  transparentPurple: ''
}
const buttonColors = computed((): string => buttonColorConfigurations[props.color])
const buttonDisabledClasses = computed((): string => {
  const showDisabledClass = props.disabled || (props.disabled && props.loading)

  return showDisabledClass ? buttonDisabledConfiguration[props.color] : ''
})
const loaderColor = computed((): string => loaderColorConfiguration[props.loaderColor])
const iconName = computed((): string => {
  if (props.loading && props.finished) {
    return 'ic_check'
  } else if (props.finished) {
    return 'ic_check'
  } else if (props.loading) {
    return 'progress'
  }
  return ''
})

const isDisabled = computed((): boolean => props.disabled || props.loading)
</script>
